<template>
    <div class="home">
        <div class="main">
            <div class="top">
                <div class="functions">
                    <div class="function-item avatar">
                        <router-link to="/profile">
                            <img class="avatar-img" :src="avatarUrl"
                                 :alt="userName">
                        </router-link>
                    </div>
                    <div class="function-item" @click="confirmLogout">
                        <i class="iconfont icon-guanji"></i>
                    </div>
                </div>
                <div class="nav-index">
                    <!--                    <i class="iconfont icon-shouye"></i>-->
                    <!--                    <span>باش بەت</span>-->
                </div>
            </div>
            <div class="slot">
                <router-view v-on:showLoading="showLoading"/>
                <div class="bot-bar">
                    <div class="main">
                        <!-- <div class="item">© riddler.cn</div> -->
                        <!--<div class="item">گۇاڭشى كاپىتان تور پەن-تېخنىكا چەكلىك شىركىتى</div>-->
                    </div>
                    <!-- <div class="meta">
                        <a href="https://riddler.cn" target="_blank">ئالاقىلىشىڭ</a>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="side">
            <side-bar/>
        </div>
    </div>
    <toast ref="toast"/>
    <loading ref="loading"/>
    <confirm-box ref="confirmBox" :content="confirmBoxContent" :click-mask-to-cancel="true" @confirm="logout"/>
</template>
<style lang="less" scoped>
    @import "../assets/less/global.less";

    @function-item-size: @top-bar-height - ( 2 * @margin-size);
    .home {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;

        .side {
            width: @side-bar-width;
            background: @color-side-bg;
            overflow-y: scroll;
        }

        .main {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin: 0 16px;

            .top {
                position: relative;
                z-index: 9;
                height: @top-bar-height;
                background: white;
                box-sizing: border-box;
                padding: @margin-size;
                line-height: @function-item-size;
                box-shadow: fade(@color-nice-black, 6%) 0 @margin-size-small @margin-size-large;
                display: flex;

                .functions {
                    flex: 1;
                    display: flex;

                    .function-item {
                        cursor: pointer;
                        width: @function-item-size;
                        text-align: center;
                        border-radius: @radius-size;
                        transition: color 200ms, background-color 400ms;
                        margin-inline-end: @margin-size;

                        &.avatar {
                            border-radius: @radius-size-half;
                            transition: transform 200ms;

                            .avatar-img {
                                width: 100%;
                                height: 100%;
                                border-radius: @radius-size-half;
                            }

                            &:hover {
                                transform: scale(1.1);
                            }
                        }

                        &:hover {
                            color: @color-primary;
                            background: fade(@color-nice-black, 4%);
                        }

                        i {
                            font-size: @font-size-large;
                        }
                    }
                }

                .nav-index {
                    color: fade(@color-nice-black, 40%);
                    direction: rtl;

                    span {
                        vertical-align: middle;
                    }

                    i {
                        vertical-align: middle;
                        margin-inline-end: @margin-size-mini;
                    }
                }
            }

            .slot {
                @bot-bar-height: 60px;
                flex: 1;
                overflow-y: scroll;

                .bot-bar {
                    height: @bot-bar-height;
                    line-height: @bot-bar-height - (2 * @margin-size);
                    background: white;
                    display: flex;
                    direction: rtl;
                    padding: @margin-size;
                    box-sizing: border-box;
                    color: fade(@color-nice-black, 60%);

                    .main {
                        flex: 1;
                        display: flex;
                        flex-direction: row;

                        .item {
                            margin-inline-end: @margin-size-xxxl;
                        }
                    }

                    .meta {
                        a {
                            color: fade(@color-nice-black, 60%);
                        }
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
</style>
<script>
    import SideBar from "../components/SideBar";
    import Toast from "../components/Toast";
    import Loading from "../components/Loading";
    import ConfirmBox from "../components/ConfirmBox";

    let self
    export default {
        name: 'Home',
        emits: ['showLoading'],
        components: {
            ConfirmBox,
            SideBar,
            Toast,
            Loading
        },
        data() {
            return {
                confirmBoxContent: '',
                avatarUrl:"",
                userName: ''
            }
        },
        created() {
            self = this
            self.loginCheck()
        },
        methods: {
            redirectToLogin() {
                self.$router.replace('/login')
            },
            confirmLogout() {
                self.confirmBoxContent = "ئارقا سۇپىدىن چېكىنىشنى جەزىملەمسىز؟"
                self.$refs.confirmBox.show()
            },
            logout() {
                localStorage.removeItem('token')
                localStorage.removeItem('loginData')
                self.redirectToLogin()
            },
            showLoading() {
                self.$refs.loading.show()
                setTimeout(() => {
                    self.$refs.loading.hide()
                }, 2000)
            },
            loginCheck() {
                if (localStorage.getItem('token') === null || localStorage.getItem('loginData') === null) {
                    self.redirectToLogin()
                }
                let loginData = localStorage.getItem('loginData')
                loginData = JSON.parse(loginData)
                self.avatarUrl = loginData.avatar
                self.userName = loginData.user_name
            }
        }
    }
</script>
